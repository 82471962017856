.container{
    width: 100%;
    margin: 0 auto;
}

.container .wrapper{
    padding: 0 48px;
}

.btn-toggle-theme{
    color: white !important;
    width: 30px;
}

.btn-toggle-theme svg{
    font-size: 16px;
}


@media only screen and (max-width: 768px){
    .btn-toggle-theme svg{
        font-size: 24px;
    }
}

.logo, 
.appBar, 
.navButton{
    color: black;
}

.App--dark-mode .logo, 
.App--dark-mode .appBar,
.App--dark-mode .navButton{
    color: white;
}

.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    -moz-transition: transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    transition: transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.logo img{
    height: 30px;
    width: auto;
}

.logo-container{
    width: fit-content;
    height: fit-content;
}

@media not all and (pointer: coarse) {
    .logo:hover{
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 768px) and (min-width: 560px) {
    .navButton {
        position: absolute;
        top: unset !important;
        bottom: 90px !important;
        right: 23px !important;
        background: #1B72F5 !important;
        height: 60px;
        width: 60px;

        animation-name: floating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-left: 30px;
        margin-top: 5px;

        box-shadow: 0 8px 16px -2.5px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 8px 16px -2.5px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 8px 16px -2.5px rgba(0, 0, 0, 0.1);
    }
}

@media only screen and (max-width: 560px) {
    .navButton{
        position: absolute;
        top: unset !important;
        bottom: 80px !important;
        right: 14px !important;
        background: #1B72F5 !important;
        height: 54px !important;
        width: 54px !important;
    }
}

@media only screen and (max-width: 768px){
    .navButton svg{
        color: white !important;
    }

    .App--dark-mode .navButton{
        background: #0c162e !important;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.21);
        -webkit-box-shadow: 0 8px 16px 0 rgba(0,0,0,0.21);
        -moz-box-shadow: 0 8px 16px 0 rgba(0,0,0,0.21);
    }

    @keyframes floating { 
        0% { transform: translate(0,  0px); } 
        50%  { transform: translate(0, 2px); } 
        100%   { transform: translate(0, -0px); }     
    } 

    .logo{
        text-align: center;
    }

    .logo-container{
        margin: 0 auto;
    }

    .logo img{
        height: 35px;
        width: auto;
    }
}

header{
    height: 100px;
}

header .hamburger-menu{
    display: none;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
}

header .hamburger-menu .menu-secondary-background-color{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: #1B72F5;
    position: absolute;
    z-index: -1;
}

header .hamburger-menu .menu-layer{
    position: relative;
    background-color: #3AA5FD;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
}

header .hamburger-menu .menu-layer .menu-hover-background{
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-size: cover;
    animation: cameraPan 30s infinite;
}

header .hamburger-menu .menu-layer .wrapper{
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .hamburger-menu .menu-layer .wrapper .menu-links{
    width: fit-content;
}

header .hamburger-menu .menu-layer .wrapper .menu-links nav{
    display: block;
    position: relative;
}

header .hamburger-menu .menu-layer .wrapper .menu-links nav ul{
    padding: 0;
    margin: 0;
}

header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li{
    font-family: 'IntegralCF-Heavy', serif;
    font-size: clamp(3.5rem, 10vw, 6rem);
    cursor: pointer;
    list-style-type: none;
    height: 140px;
    text-align: center;
}

@media only screen and (max-width: 768px){
    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li{
        height: 80px;
    }
}


header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a{
    position: absolute;
    color: white;
    text-decoration: none;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a:hover{
    color: #191919;
}

header .hamburger-menu .menu-layer .wrapper .menu-links .info{
    color: white;
    width: 20%;
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

header .hamburger-menu .menu-layer .wrapper .submenu{
    color: white;
    margin-top: 16px;
    font-size: 1rem;
    font-weight: 800;
    display: flex;
}

@media only screen and (max-width: 768px){
    header .hamburger-menu .menu-layer .wrapper .menu-links .info{
        width: 100%;
    }
    header .hamburger-menu .menu-layer .wrapper .submenu{
        display: none;
    }
}

header .hamburger-menu .menu-layer .wrapper .submenu ul{
    list-style-type: none;
}

header .hamburger-menu .menu-layer .wrapper .submenu ul li{
    display: flex;
    cursor: pointer;
    font-weight: 800;
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
    -webkit-transition: all 0.3s ease-in-out;
       -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
}

header .hamburger-menu .menu-layer .wrapper .submenu ul li:first-child{
    margin-left: 40px;
}

header .hamburger-menu .menu-layer .wrapper .submenu ul li:hover{
    letter-spacing: 1px;
}

@keyframes cameraPan{
    0% {
        background-position: 0 0;
    }
    25% {
        background-position: 40% 10%;
    }
    50% {
        background-position: 0 10%;
    }
    75% {
        background-position: 10% 40%;
    }
    100% {
        background-position: 0 0;
    }
}