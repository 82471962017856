.aws-btn {
    --button-default-height: 48px;
    --button-default-font-size: 14px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 5px;
    --button-hover-pressure: 2;
    --transform-speed: 0.185s;
    --button-primary-color: #4829FF;
    --button-primary-color-dark: #251581;
    --button-primary-color-light: #ffffff;
    --button-primary-color-hover: #3d23d9;
    --button-primary-color-active: #361fc3;
}

.aws-btn.aws-btn--link{
    --button-anchor-color: #3AA5FD ;
    --button-anchor-color-dark: #2873b1;
    --button-anchor-color-light: white;
    --button-anchor-color-hover: #3699ea;
    --button-anchor-color-active: #3087cf;
}

.aws-btn.aws-btn--secondary{
    --button-secondary-color: #DD55FF;
    --button-secondary-color-dark: #7d3091;
    --button-secondary-color-light: #ffffff;
    --button-secondary-color-hover: #cb4eea;
    --button-secondary-color-active: #b545d0;
}