.awssld {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #3b4b94;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #3b4b94;
    --control-bullet-active-color: #5468cf;
    --loader-bar-color: #5e6fbf;
    --loader-bar-height: 6px;
}

.awssld.secondary {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #3AA5FD;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #3390dd;
    --control-bullet-active-color: #8bcbff;
    --loader-bar-color: #8bcbff;
    --loader-bar-height: 6px;
}

.awssld button{
    z-index: 5;
}