.member-card{
    max-width: 220px;
    height: 300px;
    width: 100%;
    margin: 15px;
    background: #4829FF !important;
    color: white !important;
    border: 1px solid #4829FF;
    position: relative;
    -webkit-transition: transform 0.3s ease-in-out !important;
    -moz-transition: transform 0.3s ease-in-out !important;
    transition: transform 0.3s ease-in-out !important;
}

.member-card:hover{
    transform: translateY(-5px);
}

.App--dark-mode .member-card{
    border: 1px solid #242274;
}

.member-card-media{
    position: relative;
}

.member-card-content{
    background: #4829FF !important;
    padding: 10px !important;
    position: absolute;
    bottom: 0;
    width: 91%;
    height: 50px;
    -webkit-transition: height 0.2s ease-out;
    -moz-transition: height 0.2s ease-out;
    transition: height 0.2s ease-out;
}

.App--dark-mode .member-card-content{
    background: #242274 !important;
}

.member-card-content .social .MuiSvgIcon-root{
    color: white;
}

.member-card-content .social a{
    margin: 0 4px 0 4px;
}

.member-card-content .social{
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.member-card:hover .member-card-content .social{
    opacity: 1;
}

.member-card:hover .member-card-content{
    height: 80px;
}

.member-card-content .info{
    font-weight: 600;
    white-space: nowrap;
}


.member-card-content .position{
    margin: 5px auto 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 12px;
    height: 24px;
}