@font-face {  
    font-family: 'IntegralCF-Heavy';  
    font-style: normal;  
    font-display: swap; 
    unicode-range: U+000-5FF; 
    src: local('IntegralCF-Heavy'),       
    url('../src/assets/fonts/IntegralCF-Heavy.woff') format('woff2'),        
    url('../src/assets/fonts/IntegralCF-Heavy.woff2') format('woff');
}

*{
    margin: 0;
    padding: 0;
}

html, body{
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    height: 100%;
    width: 100%;
}

a,
a:hover, 
a:visited, 
a:link, 
a:active {
    text-decoration: none;
}

.App{
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #F5F6FF;
    color: black;
    overflow-x: hidden;
}

.App h1{
    color: #4829FF;
    font-family: 'IntegralCF-Heavy', sans-serif;
}

.App--dark-mode h1{
    color: white;
}

.App--dark-mode, 
.App--dark-mode .MuiPaper-root{
    background: #0F0E2D;
    color: white;
}

.App--dark-mode .MuiSvgIcon-root{
    color: white;
}

::selection {
    background: rgba(94, 111, 191, 0.2);
}

::-moz-selection {
    background: rgba(94, 111, 191, 0.2);
}

a {
    text-decoration: none;
    outline: none;
}

a:hover {
    outline: none;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
    /* Provide a fallback style for browsers
	 that don't support :focus-visible */
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
    background: transparent;
}

a:focus-visible {
    /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
    outline: 2px solid red;
    background: transparent;
}