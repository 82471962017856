.events-container{
    display: block;
    margin: 1px auto 150px auto !important;
}

@media only screen and (max-width: 697px){
    .events-container{
        margin-bottom: 200px;
    }
}

