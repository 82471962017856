.countdown-heading{
    font-family: Poppins, sans-serif !important;
    color: black !important;
    font-size: clamp(1rem, 5vw, 1.25rem);
    font-weight: 600;
    text-align: center;
    margin: 50px auto 20px auto;
    width: 60%;
}

.App--dark-mode .countdown-heading{
    color: white !important;
}

@media (max-width: 768px){
    .countdown-heading{
        margin: 0 auto 20px auto;
    }
}

.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown-item {
    color: #586680;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: #586680;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}

.App--dark-mode .countdown-item,
.App--dark-mode .countdown-item span {
    color: #ffffff;
}

.countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
}