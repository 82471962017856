.pattern-top {
    z-index: 2;
    display: block;
    width: 200%;
    height: 28px;
    background-color: transparent;
    background-image: url('../../assets/svg/triangle-top.svg');
    background-position: 100% 0;
    background-size: contain;
    background-repeat: repeat-x;
}

.pattern-top.purple{
    background-color: #FAFBFF;
}

.App--dark-mode .pattern-top.purple{
    background-color: #242273;
}

  