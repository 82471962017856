.pattern-bottom {
    position: relative;
    z-index: 2;
    display: block;
    width: 300%;
    height: 28px;
    left: -200%;
    background-color: transparent;
    background-image: url('../../assets/svg/triangle-bottom.svg');
    background-position: 0 100%;
    background-size: contain;
    background-repeat: repeat-x;
}