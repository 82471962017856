.wide-section{
    margin-top: -100px;
    height: 500px;
}

.wide-section2{
    text-align: center;
    width: 50%;
    padding: 0;
    margin: 200px auto 0 auto;
}

.wide-section .content,
.wide-section2 .content{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wide-section2 .content{
    opacity: 0;
}

.wide-section2 h4{
    font-size: clamp(1.5rem, 5vw, 2.5rem);
}

@media (min-width: 768px) and (max-width: 1028px){
    .wide-section2{
        width: 70%;
    }
}

@media only screen and (max-width: 768px){
    .wide-section,
    .wide-section2{
        text-align: left;
        padding-left: 15px;
    }

    .wide-section2{
        width: 90%;
        margin-bottom: 50px;
    }
}