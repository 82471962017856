.event-card{
    width: 80%;
    margin: 15px auto 80px auto;
    border-radius: 5px;
    padding: 10px;
    overflow: visible;
    background: #4829FF !important;
    -webkit-transition: transform 0.2s ease-in-out !important;
       -moz-transition: transform 0.2s ease-in-out !important;
            transition: transform 0.2s ease-in-out !important;
}

@media only screen and (max-width: 768px){
    .event-card{
        transform: scale(0.9);
    }
}

.App--dark-mode .event-card{
    background: #242274 !important;
}

.event-card-container{
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    transform: scale(0.9);
    opacity: 0;
    margin: 0 auto;
}

.event-card-container .event-title{
    position: relative;
    padding: 40px 40px 40px 40px;
    background: #4024e5;
    color: white;
    font-weight: 600;
    font-family: 'IntegralCF-Heavy', sans-serif;
}

.event-card-container .event-date{
    font-size: 14px;
    margin: 12px 0 25px 0;
    color: white;
}

.event-card-image-container{
    position: relative;
    overflow: hidden;
}

.event-card-image{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.event-border{
    border-left: 10px solid white;
}

.event-info{
    padding: 0 40px 0 40px;
}

.event-card-content.pr-0{
    padding-right: 0;
}

.event-card-content.p-0{
    padding: 16px 16px 16px 0;
}

.event-card-content.pl-0{
    padding-left: 0 !important;
}

@media (max-width: 959px){
    .events-container{
        margin-top: -200px !important;
    }

    .event-card{
        padding: 0;
        margin-bottom: 20px;
    }

    .event-card-image-container{
        margin: 0;
        padding: 0 !important;
    }

    .event-card-image{
        position: relative;
        border-right: none;
        margin: 0 auto;
        padding: 0;
        justify-content: center;
    }

    .event-card-content{
        padding: 0 !important;
    }

    .event-card-info-container{
        padding-bottom: 50px !important;
    }

    .event-info{
        padding-top: 20px;
    }
}

@media (min-width: 600px) and (max-width: 958px){
    .event-card{
        width: 65%;
    }

    .event-card-image {
        width: 100%;
    }

    .event-border{
        border-left: none;
    }
}

@media (max-width: 600px){
    .event-card{
        width: 100%;
    }

    .event-border{
        border-left: none;
    }
}

.App--dark-mode .event-card-container .event-date{
    color: #bbd4ff;
}

.event-card-container .event-description{
    color: white;
    font-size: 14px;
}

.App--dark-mode .event-card-container .event-description{
    color: #bbd4ff;
}

.card-button span{
    color: #5E6FBE;
    text-transform: lowercase;
    font-size: 12px;
}

.App--dark-mode .card-button span{
    color: #ffffff;
}