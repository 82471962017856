.hero{
    top: -100px;
    height: 500px;
    width: 100%;
    margin: 0 auto 0 auto;
    display: block;
    position: relative;
    background: rgb(71,42,255);
    background: linear-gradient(0deg, rgba(71,42,255,1) 0%, rgba(167,182,244,1) 100%);
}

.App--dark-mode .hero{
    background: rgb(31,29,93);
    background: linear-gradient(0deg, rgba(31,29,93,1) 0%, rgba(15,14,45,1) 100%);
}

.hero h1{
    opacity: 0;
    line-height: 1em;
    font-size: clamp(2.5rem, 10vw, 5rem);
    position: relative;
    text-align: center;
    color: white;
}

.hero h2{
    font-family: 'IntegralCF-Heavy', sans-serif;
    font-size: clamp(2rem, 10vw, 3rem);
}

.hero .hero-heading-container{
    overflow: hidden;
}

.hero .hero-heading,
.hero .hero-small-heading{
    transform: translateY(100px);
    opacity: 0;
}

.hero .hero-small-heading{
    width: 70%;
    margin: 0 auto;
    font-size: clamp(1rem, 8vw, 3rem) !important;
}

.hero .hero-subheading{
    text-align: center;
    margin-top: 20px;
    color: white;
}

.App--dark-mode .hero .hero-subheading{
    color: white;
}

@media only screen and (max-width: 768px){
    .hero .hero-heading,
    .hero .hero-small-heading{
        margin-left: 14px;
    }

    .hero .hero-subheading{
        text-align: left;
        padding-left: 20px;
    }

    .hero .hero-small-heading{
        width: 80%;
        margin: 0 auto;
    }
}

.hero.home{
    min-height: 100vh;
    background: rgb(92,64,255);
    background: linear-gradient(0deg, rgba(92,64,255,1) 0%, rgba(30,27,121,1) 100%);
    background-size: cover;
    transition: background 1s ease-in-out;
}

.hero.home::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.App--dark-mode .hero.home{
    background: rgb(31,29,93);
    background: linear-gradient(0deg, rgb(69, 65, 205) 0%, rgba(15,14,45,1) 100%);
}

.hero.home .hero-heading,
.hero.home .hero-small-heading{
    font-size: clamp(1rem, 9vw, 4rem);
}

.hero.home .hero-subheading{
    font-weight: 500;
}

.hero.home .hero-heading,
.hero.home .hero-small-heading,
.hero.home .hero-subheading{
    color:white;
}

.hero .hero-content{
    color: #2B3858;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 50px auto;
    width: 80%;
}

.hero .hero-content.align-center{
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.App--dark-mode .hero .hero-content{
    color: white;
}

@media only screen and (max-width: 768px){
    .hero .hero-content{
        width: 90%;
    }

    .hero .hero-content,
    .hero.home .hero-heading{
        text-align: left;
    }
}

.hero .btn-register{
    margin-top: 15px;
}

.hero .button-container{
    margin-top: 20px;
}

.hero .entrance-background{
    background: #3AA5FD;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.hero .entrance-background-2{
    background: #492BFF;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.hero-heading-entrance{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 100%;
    display: block;
    opacity: 0;
    color: white !important;
}

.App--dark-mode .text-secondary{
    color: white;
}

.hero .width-90{
    width: 90%;
    margin: 0 auto;
}
