.footer {
    padding: 100px 150px 100px 150px;
    position: relative;
    background: white;
    --color-text: black;
    --color--text--dark--mode: white;
}

.App--dark-mode .footer{
    background: #1b1a4a;
}

.footer:after{
    background: linear-gradient(to right, #4829FF 25%,#9e9dff 25%, #9e9dff 50%, #68C9E1 50%, #68C9E1 75%, #3AA5FD 75%);
    position: absolute;
    content: '';
    height: 4px;
    right: 0;
    left: 0;
    top: 0;
}

.footer .title{
    color: #4829FF;
}

.App--dark-mode .footer .title{
    color: #9E9DFE;
}

.footer .MuiTypography-body1{
    font-size: 14px;
}

@media only screen and (max-width: 1080px){
    .footer{
        padding: 100px;
    }   
}

@media only screen and (max-width: 768px){
    .footer{
        padding: 50px;
    }   
}

.footer .MuiListItem-root{
    overflow: hidden;
}

.footer a {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    color: var(--color-text);
}

.App--dark-mode .footer a{
    color: var(--color--text--dark--mode);
}

.footer a::before,
.footer a::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #462AFD;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.App--dark-mode .footer a::before,
.App--dark-mode .footer a::after {
    background: #9E9DFF;
}

.footer a::before {
    content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

@media not all and (pointer: coarse) {
    .footer a:hover::before {
        transform-origin: 0 50%;
        transform: scale3d(1, 1, 1);
    }
}