.loader-logo{
    display: block;
    height: 55px;
    width: auto;
    animation-name: floating; 
    animation-duration: 3s; 
    animation-iteration-count: infinite; 
    animation-timing-function: ease-in-out; 
}

@keyframes floating { 
    0% { transform: translate(0,  0px); } 
    50%  { transform: translate(0, 10px); } 
    100%   { transform: translate(0, -0px); }     
} 